// TEMPLATE - copy to src/firebase/config.js and modify accordingly

import { initializeApp } from 'firebase/app';
import {
  getFirestore,
  //connectFirestoreEmulator // COMMENT TO DEPLOY
} from 'firebase/firestore';
import {
  getAuth,
  //connectAuthEmulator // COMMENT TO DEPLOY
} from 'firebase/auth';

// init firebase - Copy the values from the Project Settings page on the Firebase project's console (and create WEB app with firebase hosting, if not done yet)
initializeApp({
  apiKey: "AIzaSyBsaghfgXrLP6wpEFaXsy9Z43mT7zHuDD4",
  authDomain: "heylight-visioni-staging.firebaseapp.com",
  projectId: "heylight-visioni-staging",
  storageBucket: "heylight-visioni-staging.firebasestorage.app",
  messagingSenderId: "495547306982",
  appId: "1:495547306982:web:b5753b77a233463fe5bc27"
  //  measurementId: "uuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuu"
});
// init services
const db = getFirestore();
const auth = getAuth();

// use emulator -- COMMENT TO DEPLOY
//connectFirestoreEmulator(db, 'holipay.visioni.io', 8080);
//connectAuthEmulator(auth, 'http://holipay.visioni.io:9099');

export { db, auth }
